<template>
  <v-container class="mt-12">
    <v-row class="pb-8">
       <div>
        <img
          style="
        position: absolute;
        right: -216px;
        width: 365px;
        opacity: 15%;"
          src="../../assets/SB icon.svg"
        />
      </div>

      <v-col cols="12" lg="6" md="6" sm="12">
        <img
        class="px-6"
          style="object-fit: cover;"
          width="100%"
          src="../../assets/aboutus-s1.jpg"
        />
      </v-col>
      <v-col cols="12" lg="6" md="6" sm="12" >
        <p
        class="accent--text caption"
          style="border-bottom: 1px solid black;
          padding-bottom: 5px;
          width: max-content;
          border-color: #a67d58;"
        >{{ $t("home.s2.HOW_WE_ARE") }}</p>
        <h2 class="accent--text">{{ $t("home.s2.Serah_al_Belad") }}</h2>
        <h4>{{ $t("home.s2.GENERAL_CONSTRUCTION") }}</h4>
        <p class="pt-6 caption">
          <b>{{$t('about.s1.SerahalBeladisone')}}</b>
        </p>
        <p class="caption">
          {{$t('about.s1.about_decs')}}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
};
</script>

<style lang="scss" scoped></style>
